import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import voidImage from '../../assets/vectors/void.svg';

import { useRouter } from '../../hooks/useRouter';

import { Colors } from '../../styles/colors';
import { GET_ME } from '../../graph/queries/me';
import { useConfirmMembership } from '../../graph/mutations/confirmMembership';

import { Copy, H1 } from '../../components/atoms/Typography';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { GlobalLoader } from '../../components/atoms/Loader';
import { Placeholder } from '../../components/atoms/Placeholder';
import { LayoutCentered } from '../../components/atoms/Layout/Layout';
import { Size } from '../../components/atoms/Placeholder/Placeholder';
import { CopyColoredSpan } from '../../components/atoms/Typography/Copy';
import { FeaturesPanel } from '../../components/organisms/FeaturesPanel';
import { PrimaryButtonLink } from '../../components/atoms/Button/Buttons';
import { ChevronRight } from '@styled-icons/material';

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

export const CheckoutConfirm = () => {
  const {
    query: {
      payment_intent: paymentIntentId,
      payment_intent_client_secret: paymentIntentClientSecret,
      redirect_status: redirectStatus,
    },
  } = useRouter();
  const [confirmMembership] = useConfirmMembership();
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  if (!paymentIntentId) {
    return (
      <div>
        <H1>Oops!</H1>
        <Copy>Looks like something went wrong. Please try again.</Copy>
      </div>
    );
  }

  useEffect(() => {
    const fetchStuff = async () => {
      const stripe = await stripePromise;

      if (!paymentIntentClientSecret) {
        return;
      }

      if (!paymentIntentId) {
        return;
      }

      if (!redirectStatus) {
        return;
      }

      if (!stripe) {
        return;
      }

      const { paymentIntent: { status } = {} } = await stripe.retrievePaymentIntent(paymentIntentClientSecret);

      let message = '';
      switch (status) {
        case 'succeeded':
          message = 'Success! Payment received.';
          await confirmMembership({
            variables: {
              data: {
                paymentIntentId,
                paymentIntentClientSecret,
                redirectStatus,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
          });

          break;
        case 'processing':
          message = "Payment processing. We'll update you when payment is received.";
          break;
        case 'requires_payment_method':
          message = 'Payment failed. Please try another payment method.';
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          break;
        default:
          message = 'Something went wrong.';
          break;
      }
      setMessage(message);
      setIsLoading(false);
    };

    fetchStuff();
  }, []);

  return (
    <div>
      <H1>Confirmed</H1>
      {isLoading ? (
        <div>
          <LayoutCentered>
            <Placeholder
              icon={voidImage}
              iconAlt="404"
              iconSize={Size.SMALL}
              title="Loading..."
              description={
                <>
                  <GlobalLoader />
                  <Copy marginBottom={16}>We are fetching your payment status. This may take a few seconds.</Copy>
                  <Copy>Do not reload the page.</Copy>
                </>
              }
            />
          </LayoutCentered>
        </div>
      ) : (
        <div>
          <Spacer y={96} />
          <FeaturesPanel title="Empowering Your Job Search Journey with AI">
            <>
              <Copy marginBottom={16}>{message}</Copy>
              <Copy marginBottom={16}>
                Welcome to the JobsBoard<CopyColoredSpan color={Colors.Primary}>.io</CopyColoredSpan> family.
              </Copy>
              <Copy styleLevel={2} marginBottom={16}>
                Now that you're a Premium member, dive into the array of enhanced features at your fingertips. From
                generated Cover Letter to exclusive insights on your Job Application, your journey to career success
                just got even more exciting.
              </Copy>
              <Copy styleLevel={2}>
                Explore your premium benefits now and make the most of your JobsBoard.io experience. Should you have any
                questions or need assistance, our team is here to help. Cheers to your success! 🎉
              </Copy>
              <Spacer y={32} />
              <PrimaryButtonLink
                to="/dashboard"
                inline={true}
                size="large"
                iconRight={<ChevronRight color={Colors.White} />}
              >
                Visit Dashboard
              </PrimaryButtonLink>
            </>
          </FeaturesPanel>
          {/* <Features hideHeader={true} hideFreeFeatures={true} /> */}
        </div>
      )}
    </div>
  );
};

export default CheckoutConfirm;
