import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationConfirmMembershipArgs } from '../../generated/types';

export const CONFIRM_MEMBERSHIP = gql`
  mutation ConfirmMembership($data: ConfirmMembershipInputData!) {
    confirmMembership(data: $data)
  }
`;

export const useConfirmMembership = (
  options?: MutationHookOptions<Pick<Mutation, 'confirmMembership'>, MutationConfirmMembershipArgs>
) =>
  useMutation<Pick<Mutation, 'confirmMembership'>, MutationConfirmMembershipArgs>(CONFIRM_MEMBERSHIP, {
    ...options,
  });
